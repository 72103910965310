import { render, staticRenderFns } from "./CustomerProfileSummary.vue?vue&type=template&id=0ab93c00&scoped=true"
import script from "./CustomerProfileSummary.vue?vue&type=script&lang=js"
export * from "./CustomerProfileSummary.vue?vue&type=script&lang=js"
import style0 from "./CustomerProfileSummary.vue?vue&type=style&index=0&id=0ab93c00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab93c00",
  null
  
)

export default component.exports